import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout/Layout";
import BlogPost from "../components/dynamicBlogs/BlogPost";

function DynamicBlog({ data }) {
  return (
      <BlogPost blogData={data?.dynamicBlogs} />
  );
}

export const Head = ({ data }) => {
  const seoDetails = data.dynamicBlogs.data?.seo_details || [];

  return (
    <>
      <title>{seoDetails?.[0]?.meta_title}</title>
      <meta
        name="description"
        content={seoDetails?.[0]?.meta_description || ""}
      />
    </>
  );
};

export const query = graphql`
  query GetDynamicBlog($id: String!) {
    dynamicBlogs(id: { eq: $id }) {
      id
      index
      name
      url
      description
      data {
        image_url
        short_desc
        content {
          config {
            data
          }
        }
        author_details
        category_details
        seo_details {
          blog_name
          blog_url
          meta_title
          meta_keywords
          meta_description
        }
        tags
      }
      related_blogs {
        id
        name
        description
        short_desc
        url
        image_url
      }
      status
      created_at
    }
  }
`;

DynamicBlog.Layout = Layout
export default DynamicBlog;
