import React from "react";
import { Link } from "gatsby";
import BlogAuthorImage from "../../../static/img/blog-author.jpg";
import { convertTimeStampToDate } from "../../utils/common";
import defaultImage from "../../../static/img/default-bc-product.png";
import Image from "../common/Image";
import { blogStaticImg } from "../../ApiEndpoints";
import { sliceAfterMatch } from "../../utils/common";

function BlogPost({ blogData }) {
  const config_data = blogData?.data?.content[0]?.config?.data;

  const limitWords = (text, limit) => {
    const words = text.trim().split(" ");
    const limitedWords = words.slice(0, limit);
    return limitedWords.join(" ");
  };

  return (
    <div className="page-wrapper">
      {/* <div className="container">
          Show Breadcrumb here
          <br />
          <br />
        </div> */}

      <div className="blog-post-section">
        <div className="container-x-small">
          <div className="blog-post-title">
            <h1 className="font-bold text-center">{blogData?.name}</h1>
          </div>
          <div className="blog-post-content">
            <div className="blog-post-image">
              {blogData?.data?.image_url.includes("/blogs/images") ? (
                <Image                  
                  src={`${process.env.GATSBY_IMAGE_CDN_BASEURL
                  }${blogStaticImg}${sliceAfterMatch(
                    blogData?.data?.image_url,
                    "/blogs/images"
                  )}`}                          
                  alt={blogData?.name}
                  title={blogData?.name}
                />
              ) : (
                <Image
                  src={defaultImage}
                  alt={blogData?.name}
                  title={blogData?.name}
                />
              )}
            </div>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: config_data,
              }}
            ></div>
          </div>
          <div className="blog-bottom-section">
            <div className="blog-author-block flex vertical-middle">
              <div className="blog-author-img">
                <Image
                  src={BlogAuthorImage}
                  alt="Blog Author Image"
                />
              </div>
              <div className="blog-author-detail">
                <h4>{blogData?.data?.author_details}</h4>
                <p>
                  {blogData?.created_at
                    ? convertTimeStampToDate(blogData?.created_at)
                    : ""}
                </p>
              </div>
            </div>

            {blogData?.data?.tags.length ? (
              <div className="blog-tag-section flex vertical-middle">
                <p>Tags:</p>
                <p className="tag-list">
                  {blogData?.data?.tags?.map((tag) => (
                    <Link>{tag}</Link>
                  ))}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {blogData?.related_blogs?.length ? (
        <div className="blog-related-section">
          <div className="container-small">
            <div className="blog-list flex flex-wrap vertical-top">
              {blogData?.related_blogs?.map((relatedBlog, index) => (
                <div className="blog-list-item" key={index}>
                  <div className="blog-image-block">
                    <Link to={relatedBlog?.url}>
                      {relatedBlog?.image_url.includes("/blogs/images")  ? (
                        <Image                          
                          src={`${process.env.GATSBY_IMAGE_CDN_BASEURL
                          }${blogStaticImg}${sliceAfterMatch(
                            relatedBlog?.image_url,
                            "/blogs/images"
                          )}`}                             
                          alt={relatedBlog?.name}
                          title={relatedBlog?.name}
                        />
                      ) : (
                        <Image
                          src={defaultImage}
                          alt={relatedBlog?.name}
                          title={relatedBlog?.name}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="blog-detail-block">
                    <h2 className="font-bold h3">
                      <Link to={relatedBlog?.url}>{relatedBlog?.name}</Link>
                    </h2>
                    <p
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: limitWords(relatedBlog?.short_desc, 163),
                      }}
                    ></p>
                    <Link className="read-more-link" to={relatedBlog?.url}>
                      Read More
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BlogPost;
